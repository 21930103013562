import React from 'react';
import { Badge, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Background from '../components/Background';
import Header from '../components/Header';
import Footer from '../components/Footer';
import RightArticle from '../components/RightArticle';
import LeftArticle from '../components/LeftArticle';
import TopArticle from '../components/TopArticle';
import BottomArticle from '../components/BottomArticle';
import SimpleList from '../components/SimpleList';
import Spacer from '../components/Spacer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'


export default class AboutPage extends React.Component {
  constructor () {
    super();
    this.state = {
      showModal: false,
      modalTitle: '',
      modalBody: '',
    };

    this.founders = [
      {
        type: 'top',
        title: 'Meet the Founders',
        img: {}
      },
      {
        type: 'right',
        title: 'Chris Conti - CEO',
        body: (
          <>
            <div>
              <p>Chris has devoted his professional life to the study and practice of chemistry. With a double major in Chemical Engineering and Chemistry supplemented by an MBA (all from the University of Kansas), he has poised himself to innovate in the testing industry with entrepreneurial spirit, a legacy of scientific dedication, and a nose for business opportunity. <span className="link-me" onClick={() => this.toggle('chris')}>Read More...</span></p>
            </div>
          </>
        ),
        img: {
          src: 'assets/images/Chris-Conti-Headshot.jpg',
          alt: 'Image of Bud',
        },
        links: [
          {
            title: <FontAwesomeIcon style={{fontSize: "2rem"}} icon={faEnvelope} />,
            url: 'mailto:cconti@conticorplabs.com',
          }
        ]
      },
      {
        type: 'left',
        title: 'Kendra Conti - President',
        body: (
          <div>
            <p>Kendra excels at strategic thinking and consummate professionalism. From her early passion for hard sciences in a family of biologists-in-trade culminating in her double major in chemistry and biology from the University of Kansas, she endeavors to add the rigor to scientific exploration. In her “short” five years as Director of Global Sales in the medical device testing industry, she sought to push existing knowledge bases to uncover the potential in the new and novel. <span className="link-me" onClick={() => this.toggle('kendra')}>Read More...</span></p>
          </div>
        ),
        img: {
          src: 'assets/images/Kendra-Conti-Headshot.jpg',
          alt: 'Image of Kendra',
        },
        links: [
          {
            title: <FontAwesomeIcon style={{fontSize: "2rem"}} icon={faEnvelope} />,
            url: 'mailto:kconti@conticorplabs.com',
          },
        ]
      },
      {
        type: 'right',
        title: 'James Conti - Advisory Board',
        body: (
          <div>
            <p>James C. Conti earned his PhD in analytical chemistry from the University of Kansas with pioneering investigations into effects of pharmaceutical chemicals on mammalian brains. He continued research by joining Ethicon where he managed development of implantable medical devices.</p>
            <p>For the past 35 years, Jim has served as the Vice President and Chief Technical officer of Dynatek Labs, an esteemed medical device testing laboratory who maintains an excellent rapport with the US FDA. He serves on multiple international standard committees that guide the industry on best practices for developing and evaluating safety of implantable medical devices.</p>
          </div>
        ),
        img: {
          src: 'assets/images/JimConti.png',
          alt: 'Image of Jim',
        },
      },
      {
        type: 'left',
        title: 'Elaine Strope - Advisory Board',
        body: (
          <div>
            <p>Elaine R. Strope earned her PhD in analytical chemistry from the University of Kansas with her trailblazing development of surgical and sampling techniques to perform electrochemical research on the mammalian brain. After finishing her doctorate, Elaine joined Johnson and Johnson in their Personal Products division managing the chromatography laboratory.</p>
            <p>Elaine Strope is President and CEO of Dynatek Labs, an ISO/IEC 17025:2017 accredited testing laboratory with 35 years of experience testing medical devices. Her strategic approach to product line development and service diversification has launched the company to renown in the industry.</p>
          </div>
        ),
        img: {
          src: 'assets/images/ElaineStrope.png',
          alt: 'Image of Elaine',
        },
      },
      {
        type: 'top',
        title: 'Galena, MO',
        body: (<p>Galena is the seat of the county government of Stone County. The historic town square hosts the county government offices, an existing medical testing lab, a title company, and two lawyers offices that service the county. City and county government has traditionally been very friendly to businesses that operate in the city.</p>),
        img: {}
      },
    ]

    this.additional_contacts = {
        items: [
          <a href="mailto:info@conticorplabs.com">info@conticorplabs.com</a>,
          <a href="mailto:support@conticorplabs.com">support@conticorplabs.com</a>,
          <a href="mailto:quality@conticorplabs.com">quality@conticorplabs.com</a>,
          <a href="mailto:accounting@conticorplabs.com">accounting@conticorplabs.com</a>,
        ]
      };

    this.team = [
      {
        type: 'top',
        title: 'Meet the Team',
        img: {}
      },
      {
        type: 'right',
        title: 'Elani Barber',
        body: (
          <>
            <div>
              <b>Operations Manager</b>
            </div>
          </>
        ),
        img: {
          src: 'assets/images/Elani-Barber-Headshot.jpg',
          alt: 'Image of Elani',
        },
        links: [
          {
            title: <FontAwesomeIcon style={{fontSize: "2rem"}} icon={faEnvelope} />,
            url: 'mailto:ebarber@conticorplabs.com',
          }
        ]
      },
      {
        type: 'left',
        title: 'Skye Wright',
        body: (
          <>
            <div>
              <b>Regional Sales Representative</b>
            </div>
          </>
        ),
        img: {
          src: 'assets/images/Skye-Wright-Headshot.jpg',
          alt: 'Image of Skye',
        },
        links: [
          {
            title: <FontAwesomeIcon style={{fontSize: "2rem"}} icon={faEnvelope} />,
            url: 'mailto:swright@conticorplabs.com',
          }
        ]
      },
      {
        type: 'right',
        title: 'Adam Costello',
        body: (
          <>
            <div>
              <b>Quality Assurance Manager</b>
            </div>
          </>
        ),
        img: {
          className: 'blank',
          src: 'assets/images/Empty.png',
          alt: 'blank spacer image',
        },
        links: [
          {
            title: <FontAwesomeIcon style={{fontSize: "2rem"}} icon={faEnvelope} />,
            url: 'mailto:quality@conticorplabs.com',
          }
        ]
      },
      {
        type: 'left',
        title: 'McKenzie Fletcher',
        body: (
          <>
            <div>
              <b>Laboratory Supervisor</b>
            </div>
          </>
        ),
        img: {
          src: 'assets/images/McKenzie-Fletcher-Headshot.jpg',
          alt: 'Image of McKenzie',
        },
      },
      {
        type: 'right',
        title: 'Steven StClair',
        body: (
          <>
            <div>
              <b>Microbiologist/Assistant Chemist</b>
            </div>
          </>
        ),
        img: {
          src: 'assets/images/Steven-St.Clair-Headshot.jpg',
          alt: 'Image of Steven',
        },
      },
      {
        type: 'left',
        title: 'Courtney Bush',
        body: (
          <>
            <div>
              <b>Laboratory Technician</b>
            </div>
          </>
        ),
        img: {
          src: 'assets/images/Courtney-Bush-Headshot.jpg',
          alt: 'Image of Courtney',
        },
      },
      {
        type: 'right',
        title: 'Daniel Ruedin',
        body: (
          <>
            <div>
              <b>Laboratory Technician</b>
            </div>
          </>
        ),
        img: {
          className: 'blank',
          src: 'assets/images/Empty.png',
          alt: 'blank spacer image',
        },
      },
      {
        type: 'left',
        title: 'Zach Flint',
        body: (
          <>
            <div>
              <b>Associate Chemist</b>
            </div>
          </>
        ),
        img: {
          src: 'assets/images/Zach-Flint-Headshot.jpg',
          alt: 'Image of Zach',
        },
      },
      {
        type: 'right',
        title: 'Erin McCord',
        body: (
          <>
            <div>
              <b>Office Assistant/Bookkeeper</b>
            </div>
          </>
        ),
        img: {
          src: 'assets/images/Erin-McCord-Headshot.jpg',
          alt: 'Image of Erin',
        },
        links: [
          {
            title: <FontAwesomeIcon style={{fontSize: "2rem"}} icon={faEnvelope} />,
            url: 'mailto:accounting@conticorplabs.com',
          }
        ]
      },
      {
        id: 'transporters',
        type: 'left',
        title: 'Steve Levingston',
        body: (
          <>
            <div>
              <b>Transport Specialists</b>
            </div>
          </>
        ),
        img: {
          src: 'assets/images/Steve-Levingston-Headshot.jpg',
          alt: 'Image of Steve',
        },
      },
      {
        type: 'right',
        title: 'Scott Carpenter',
        body: (
          <>
            <div>
              <b>Transport Specialists</b>
            </div>
          </>
        ),
        img: {
          src: 'assets/images/Scott-Carpenter-Headshot.jpg',
          alt: 'Image of Steve',
        },
      },
      {
        type: 'left',
        title: 'Jim Rieker',
        body: (
          <>
            <div>
              <b>Transport Specialists</b>
            </div>
          </>
        ),
        img: {
          src: 'assets/images/Jim-Reiker-Headshot.jpg',
          alt: 'Image of Jim',
        },
      },
      {
        type: 'right',
        title: 'Ed Kelly',
        body: (
          <>
            <div>
              <b>Transport Specialists</b>
            </div>
          </>
        ),
        img: {
          className: 'blank',
          src: 'assets/images/Empty.png',
          alt: 'blank spacer image',
        },
      },
      {
        type: 'top',
        title: 'Additional Ways to Reach Out',
        img: {}
      },
      {
        type: 'bottom',
        title: '',
        body: (
          <>
            <div>
              <SimpleList data={this.additional_contacts} />
            </div>
          </>
        ),
        img: {
          src: 'assets/images/group-photo.jpg',
          alt: 'ContiCorp group photo',
        }
      }
    ]

    this.toggle = this.toggle.bind(this);
  }

  toggle (name) {
    const { showModal } = this.state;
    let { modalBody, modalTitle } = this.state;
    // set content of modal based on Name.
    switch (name) {
      case 'chris':
        modalBody = (
          <>
            <p style={{fontSize: "16px"}}>Chris has devoted his professional life to the study and practice of chemistry. With a double major in Chemical Engineering and Chemistry supplemented by an MBA (all from the University of Kansas), he has poised himself to innovate in the testing industry with entrepreneurial spirit, a legacy of scientific dedication, and a nose for business opportunity.</p>
            <p style={{fontSize: "16px"}}>His work for the past eight years as the Senior Engineer in a medical device testing lab uniquely informs his endeavors in this new industry. Being responsible for test and equipment design/development, managing international customer relations, and driving business revenues in a highly-regulated technical field has forged his wisdom and acumen with fortuitous timing. Having been tasked with the foremost responsibility of establishing and maintaining the competitive advantage in a bristling Chinese export market, Chris’s consistency, dedication, and frequent travel interacting with customers, regulatory representatives, and distributors was a distinct x-factor to establishing the lab’s brand and dominance overseas.</p>
            <p style={{fontSize: "16px"}}>Concurrently, Chris spearheaded the contract testing function of lab operations, ensuring that all testing assets delivered validated results within tight timelines to meet and exceed customer requests and expectations, fostering goodwill and driving subsequent recurring sales achievements. Honing his craft in development of new testing techniques for experimental medical devices, he revolutionized the scene, pioneering advances in testing techniques and protocols for the evaluation of the medical device safety that have become the industry standard worldwide. Capitalizing on his diligence to process, creativity and hard work, he partnered to achieve an ISO/IEC 17025:2017 certification for the lab.</p>
            <p style={{fontSize: "16px"}}>Building upon this ISO/IEC 17025:2017 success, the journey has now evolved into a new industry. Chris looks forward to combining all facets of his experience and passion to impact the health and wellness of his neighbors, community, and fellow Missourians.</p>
          </>
        );
        modalTitle = 'Chris Conti - CEO';
        
        break;
      case 'kendra':
        modalBody = (
          <>
            <p style={{fontSize: "16px"}}>Kendra excels at strategic thinking and consummate professionalism. From her early passion for hard sciences in a family of biologists-in-trade culminating in her double major in chemistry and biology from the University of Kansas, she endeavors to add the rigor to scientific exploration. In her “short” five years as Director of Global Sales in the medical device testing industry, she sought to push existing knowledge bases to uncover the potential in the new and novel.</p>
            <p style={{fontSize: "16px"}}>Her growth into this role was substantial. Ample prior experience with operations of testing equipment and reporting, combined with state of the art equipment and information management systems led to her desire to design their processes. Development of testing protocols in collaboration with regulatory authorities and customers, and ensuring exceptional outcomes with customer service bred her customer-centric vision. In tandem, the laboratory’s approach to “business-as-usual” has taken an inspired positive trajectory.</p>
            <p style={{fontSize: "16px"}}>Through a sales lens, Kendra brought structure and standardization strategies to drive more holistic business decision-making processes. Leveraging organization-wide data, she was able to comprehensively evaluate complex business ideas to determine the right variables in the equation for business success. By championing unrelenting professionalism in design and execution with end user in mind, Kendra has been the true backbone of the organization. From meeting notes to strategic planning- everything has her stamp on it. </p>
            <p style={{fontSize: "16px"}}>As an analyst at heart, her approach of “evaluate all scenarios” makes the decision to transition industries a no-brainer. As evidenced by her footprint in the partnership to gain ISO/IEC 17025:2017 certification for the medical testing lab, she has an eye for Quality Assurance and a penchant for documentation. In her thoughts, there is no better merger of biology and chemistry than in medical marijuana, and she looks forward to focusing her laser-like attention on a novel subject.  </p>
            <p style={{fontSize: "16px"}}>Building upon this ISO/IEC 17025:2017 success, the journey has now evolved into a new industry. Kendra looks forward to combining all facets of her experience and passion to impact the health and wellness of her neighbors, community, and fellow Missourians. </p>
            <p style={{fontSize: "16px"}}>"Profitability is one thing, and important; but whole-client and whole-employee care is the utmost. Employee engagement, efficiency, and effective team building result in smarter management of people, smarter management of money, and smarter uses of time" -Kendra.</p>
          </>
        );
        modalTitle = 'Kendra Conti - President';
        break;
      case 'jim':
        modalBody = 'jim';
        modalTitle = 'jim';
        break;
      case 'elaine':
        modalBody = 'elaine';
        modalTitle = 'elaine';
        break;
      default:
        modalBody = '';
        modalTitle = '';
        break;
    }
    this.setState({ 
      showModal: !showModal,
      modalBody,
      modalTitle
    })
  }
  
  render () {
    const {
      showModal,
      modalTitle,
      modalBody
    } = this.state;

    return (
      <div className="page-container">
        <Header />
        <div className="page about">
          <h1 className="page__title">
            Our People
          </h1>
          <Spacer />
          <Modal centered={true} scrollable={false} size="xl" isOpen={showModal} toggle={e => this.toggle('chris')}>
            <ModalHeader toggle={e => this.toggle('chris')}>{modalTitle}</ModalHeader>
            <ModalBody>
              {modalBody}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={e => this.toggle('chris')}>Close</Button>
            </ModalFooter>
          </Modal>
          <Modal centered={true} scrollable={false} size="xl" isOpen={showModal} toggle={e => this.toggle('kendra')}>
            <ModalHeader toggle={e => this.toggle('kendra')}>{modalTitle}</ModalHeader>
            <ModalBody>
              {modalBody}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={e => this.toggle('kendra')}>Close</Button>
            </ModalFooter>
          </Modal>
          { this.founders.map((item, index) => {
            if (item.type === 'right') {
              return (
                <React.Fragment key={Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)}>
                  <RightArticle data={item} key={`${index}--${item.title.replace(/\s+/g, '-').toLowerCase()}`} />
                  { index !== (this.founders.length - 1) &&
                    <Spacer key={`s${index}-${item.title}`}/>
                  }
                </React.Fragment>
              );
            } else if (item.type === 'left') {
              return (
                <React.Fragment key={Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)}>
                  <LeftArticle data={item} key={`${index}--${item.title.replace(/\s+/g, '-').toLowerCase()}`} />
                  { index !== (this.founders.length - 1) &&
                    <Spacer key={`s${index}-${item.title}`}/>
                  }
                </React.Fragment>
              );
            } else if (item.type === 'top') {
              return (
                <React.Fragment key={Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)}>
                  <TopArticle data={item} key={`${index}--${item.title.replace(/\s+/g, '-').toLowerCase()}`} />
                  { index !== (this.founders.length - 1) &&
                    <Spacer key={`s${index}-${item.title}`}/>
                  }
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment key={Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)}>
                  <BottomArticle data={item} key={`${index}--${item.title.replace(/\s+/g, '-').toLowerCase()}`} />
                  { index !== (this.founders.length - 1) &&
                    <Spacer key={`s${index}-${item.title}`}/>
                  }
                </React.Fragment>
              );
            }
          })}
          <Spacer />
          { this.team.map((item, index) => {
            if (item.type === 'right') {
              return (
                <React.Fragment key={Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)}>
                  <RightArticle data={item} key={`${index}--${item.title.replace(/\s+/g, '-').toLowerCase()}`} />
                  { index !== (this.founders.length - 1) &&
                    <Spacer key={`s${index}-${item.title}`}/>
                  }
                </React.Fragment>
              );
            } else if (item.type === 'left') {
              return (
                <React.Fragment key={Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)}>
                  <LeftArticle data={item} key={`${index}--${item.title.replace(/\s+/g, '-').toLowerCase()}`} />
                  { index !== (this.founders.length - 1) &&
                    <Spacer key={`s${index}-${item.title}`}/>
                  }
                </React.Fragment>
              );
            } else if (item.type === 'top') {
              return (
                <React.Fragment key={Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)}>
                  <TopArticle data={item} key={`${index}--${item.title.replace(/\s+/g, '-').toLowerCase()}`} />
                  { index !== (this.founders.length - 1) &&
                    <Spacer key={`s${index}-${item.title}`}/>
                  }
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment key={Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)}>
                  <BottomArticle data={item} key={`${index}--${item.title.replace(/\s+/g, '-').toLowerCase()}`} />
                  { index !== (this.founders.length - 1) &&
                    <Spacer key={`s${index}-${item.title}`}/>
                  }
                </React.Fragment>
              );
            }
          })}
        </div>
        <Footer />
        {/* <Background /> */}
      </div>
    );
  }
}
