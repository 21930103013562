import React, { useState } from 'react';
import { Collapse, CardBody, Card, CardHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

const AccordionItem = (props) => {

    const { data } = props;

    const [isOpen, setIsOpen] = useState(data.startOpen ? true : false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <Card className="accordion">
            <CardHeader className="accordion__title" onClick={() => toggle()}>
                <span className="font-weight-bold">{ data.title }</span><FontAwesomeIcon icon={ isOpen ? faMinus : faPlus } />
            </CardHeader>
            <Collapse  isOpen={isOpen}>
                <CardBody className="accordion__body">
                    { data.body }
                </CardBody>
            </Collapse>
        </Card>
    );
};
  
export default AccordionItem;