import React from 'react';
import { Badge } from 'reactstrap';

const MyLink = (props) => (
    <a className="article__links" key={Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)} href={props.data.url} target="_blank">
        <Badge color="primary" pill>
            {props.data.title}
        </Badge>
    </a>
);
 
export default MyLink;