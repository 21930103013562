import React, { useState } from 'react';
import { NavLink as NLink } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

const DesktopNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="nav__wrapper">
      <Navbar color="white" light expand="md">
        <NavbarBrand to="/" tag={NLink}>
          <img className="nav__logo" src="assets/images/banner.jpg" alt="Conti Corps Labs Logo"></img>
        </NavbarBrand>
        <div className="menus">
          {/* <Nav className="nav__social" navbar>
            <NavItem>
              <NavLink className="header-link header-link--fb" href="https://www.facebook.com/ContiCorp-Labs-101037882270478" target="_blank"><img src="assets/images/fb-logo.svg" alt="Facebook logo and link" ></img></NavLink>
            </NavItem>
          </Nav> */}
          <Collapse isOpen={isOpen} navbar>
            <Nav className="nav__menu" navbar>
              <NavItem>
                <NavLink tag={NLink} to="/" activeClassName="active" exact path="/">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={NLink} to="/services" activeClassName="active">Services Offered</NavLink>
              </NavItem>
              <NavItem>
                <a className="nav-link" href="https://accounts.confidentcannabis.com/login" target="_blank">Submit An Order <FontAwesomeIcon style={{fontSize: "1em"}} icon={faExternalLinkAlt} /></a>
              </NavItem>
              <NavItem>
                <NavLink tag={NLink} to="/about" activeClassName="active">About Us</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={NLink} to="/faq" activeClassName="active">FAQs</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={NLink} to="/contact-us" activeClassName="active">Contact Us</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
          <NavbarToggler onClick={toggle} />
        </div>
      </Navbar>
    </div>
  );
};

export default DesktopNav;
