import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import HomePage from '../pages/HomePage';
import AboutPage from '../pages/AboutPage';
import ServicesPage from '../pages/ServicesPage';
import ContactPage from '../pages/ContactPage';
import FAQPage from '../pages/FAQPage';
import NotFoundPage from '../pages/NotFoundPage';
// More components here!

const routes = [
{
  path: '/',
  component: HomePage,
},
{
  path: '/about',
  component: AboutPage,
},
{
  path: '/services',
  component: ServicesPage,
},
{
  path: '/contact-us',
  component: ContactPage,
},
{
  path: '/faq',
  component: FAQPage,
},
// more routes here.
];

class AppRouter extends React.Component {
  render() {
    const routeComponents = routes.map(({path, component}, key) => <Route exact path={path} component={component} key={key} />);
    return (
      <Router>
        <Switch>
          {routeComponents}
          <Route component={HomePage} />
        </Switch>
      </Router>
    );
  }
};

export default AppRouter;