import React from 'react';
import Background from '../components/Background';
import Header from '../components/Header';
import Footer from '../components/Footer';
import RightArticle from '../components/RightArticle';
import LeftArticle from '../components/LeftArticle';
import TopArticle from '../components/TopArticle';
import Spacer from '../components/Spacer';
import SimpleList from '../components/SimpleList';

const inhalablesListItems = {
  items: [
    <span><strong>Inhalables:</strong></span>,
  ],
  subItems: [
    'Flower',
    'Prerolls',
    'Concentrates',
  ]
};

const ingestiblesListItems = {
  items: [
    <span><strong>Inhalables:</strong></span>,
  ],
  subItems: [
    'Gummies',
    'Chocolates',
    'Drinks/Drink Powders',
    'Other Edible Products',
  ]
};

const potencyItems = {
  items: [
    'CBDA',
    'CBD',
    'CBDV',
    'CBN',
    'delta 9 THC',
    'delta 8 THC',
    'THCA',
    'THCV',
  ]
}

const items = [
  {
    id: 'intro',
    type: 'left',
    title: 'Current Testing Matrices',
    body: (
      <div className="services--info">
        <SimpleList data={inhalablesListItems} />
        <SimpleList data={ingestiblesListItems} />
      </div>
    ),
    img: {
      src: 'assets/images/pipette_green.jpg',
      alt: 'Pipettes in different shades of green',
    },
  },
  {
    id: 'transport',
    type: 'right',
    title: 'Transportation',
    body: (
      <div className="services--info">
        Our staff are certified to travel to customer facilities, sample product for testing, and transport it to our lab. This integration results in the fastest possible turnaround. Contact today to arrange a sample pickup.
      </div>
    ),
    img: {
      src: 'assets/images/rainbow_vials.jpg',
      alt: 'Scientist Sampling Image',
    },
    links: [
      {
        title: 'Contact Us',
        url: '/contact-us',
      },
      {
        title: 'Meet The Team',
        url: '/about#transporters',
      }
    ]
  },
  {
    id: 'potency',
    type: 'left',
    title: 'Potency',
    body: (
      <div className="services--info">
        <div className="services--instrument">
          <h3>Instrumentation used:</h3>
          <h3 className="services--instrument--indent">High Performance Liquid Chromatography (HPLC)</h3>
        </div>
        <Spacer />
        <p>The concentration of compounds must be verified in order to ensure both consumer safety as well as efficacy of the prescription purchased.</p>
        <p>We currently report potency results for the following cannabinoids:</p>
        <SimpleList data={potencyItems} />
      </div>
    ),
    img: {
      src: 'assets/images/potency_data_analysis.jpg',
      alt: 'Potency-Image',
    },
  },
  {
    id: 'microbiological_screening',
    type: 'right',
    title: 'Microbiological Screening',
    body: (
      <div className="services--info">
        <div className="services--instrument">
          <h3>Instrumentation used:</h3>
          <h3 className="services--instrument--indent">Polymerase Chain Reaction (PCR)</h3>
        </div>
        <Spacer />
        <p>Microbiological contaminants can cause harm if inhaled or ingested. ContiCorp Labs uses a state of the art polymerase chain reaction acceleration instrument to rapidly screen for microbial contaminants such as E coli, Salmonella, and Aspergillus.</p>
        <p>We also offer plated method testing for microbial screening confirmation on concentrates and flower</p>
      </div>
    ),
    img: {
      src: 'assets/images/micro_bio_2.jpg',
      alt: 'Microbial-Image',
    }
  },
  {
    id: 'pesticide_contamination',
    type: 'left',
    title: 'Pesticide Contamination',
    body: (
      <div className="services--info">
        <div className="services--instrument">
          <h3>Instrumentation used:</h3>
          <h3 className="services--instrument--indent">Liquid Chromatography Mass Spectrometer (LCMS)</h3>
          <h3 className="services--instrument--indent">Headspace Gas Chromatography mass spectrometer (GCMS)</h3>
        </div>
        <Spacer />
        Pesticides are molecules that have been engineered to kill insects and other pests in both agricultural and domestic settings. In humans, pesticide poisoning may lead to conditions such as cancer, organ failure, skin lesions, and death. ContiCorp Labs leverages state of the art technology and our deep institutional knowledge to provide screening for over 60 commonly used pesticides.
      </div>
    ),
    img: {
      src: 'assets/images/potency_pipette.jpg',
      alt: 'Pesticides-Image',
    }
  },
  {
    id: 'heavy_metals',
    type: 'right',
    title: 'Heavy Metals',
    body: (
      <div className="services--info">
        <div className="services--instrument">
          <h3>Instrumentation used:</h3>
          <h3 className="services--instrument--indent">Inductively Coupled Plasma Mass Spectrometer (ICPMS)</h3>
        </div>
        <Spacer />
        Heavy metals have been known to cause lasting damage to humans and animals. These metals can infiltrate ground water that is used for the growth or processing of plants. ContiCorp Labs provides a thorough screening for heavy metal contamination, including arsenic, cadmium, chromium, lead, and mercury.
      </div>
    ),
    img: {
      src: 'assets/images/metals.jpg',
      alt: 'Heavy Metals-Image',
    }
  },
  {
    id: 'residual_solvents',
    type: 'left',
    title: 'Residual Solvents',
    body: (
      <div className="services--info">
        <div className="services--instrument">
          <h3>Instrumentation used:</h3>
          <h3 className="services--instrument--indent">Gas Chromatograph Mass Spectrometer</h3>
        </div>
        <Spacer />
        <p>Industrial solvents are commonly used during plant matter extraction. These solvents must be adequately removed before consumption. ContiCorp Labs offers screening to ensure that residual solvents are within safe-to-consume limits.</p>
        <p>ContiCorp offers screening of 15+ residual solvents to ensure that final products are within safe-to-consume limits.</p>
      </div>
    ),
    img: {
      src: 'assets/images/gcms.jpg',
      alt: 'Residual Solvents-Image',
    }
  },
  {
    id: 'water_activity',
    type: 'right',
    title: 'Water Activity and Moisture Content',
    body: (
      <div className="services--info">
        <div className="services--instrument">
          <h3>Instrumentation used:</h3>
          <h3 className="services--instrument--indent">Water Activity Meter</h3>
          <h3 className="services--instrument--indent">Moisture Balance</h3>
        </div>
        <Spacer />
        High water activity and moisture content can facilitate rapid growth of microbes and increase the rate of degradation, thereby reducing shelf life. ContiCorp Labs utilizes an industry standard water activity meter and moisture balance to ensure that a products meet or exceed regulatory standards.
      </div>
    ),
    img: {
      src: 'assets/images/water_activity_2.jpg',
      alt: 'Water Activity-Image',
    }
  },
  {
    id: 'foreign_matter',
    type: 'left',
    title: 'Foreign Matter',
    body: (
      <div className="services--info">
        <div className="services--instrument">
          <h3>Apparatus used:</h3>
          <h3 className="services--instrument--indent">Precision Sieve</h3>
          <h3 className="services--instrument--indent">Optical Microscope</h3>
        </div>
        <Spacer />
        Foreign matter such as sticks, stems, hair, insects and dirt degrade cosmetic appeal and may pose a health hazard. ContiCorp Labs uses tested and validated techniques to ensure that products comply with all relevant standards for foreign matter.
      </div>
    ),
    img: {
      src: 'assets/images/foreign_matter_2.jpg',
      alt: 'Foreign Matter-Image',
    }
  },
  {
    id: 'terpene_profile',
    type: 'right',
    title: 'Terpene Profile',
    body: (
      <div className="services--info">
        <div className="services--instrument">
          <h3>Instrumentation used:</h3>
          <h3 className="services--instrument--indent">Headspace Gas Chromatograph Mass Spectrometer (GCMS)</h3>
        </div>
        <Spacer />
        <p>Terpenes are the volatile organic compounds that give plants and other natural compounds their distinctive smells and flavors. The relative content of different terpenes in plants are what classify it as having a “piney” or “fruity” smell and flavor. ContiCorp offers a terpene profiling to boost marketability of products by highlighting their distinct aromatic characteristics.</p>
        <p>ContiCorp offers terpene profiling of 20+ terpenes to boost the marketability of products and consumer interest by highlighting distinct aromatic characteristics.</p>
      </div>
    ),
    img: {
      src: 'assets/images/terpene_profile_2.jpg',
      alt: 'Cannabis Image',
    }
  },
  {
    id: 'voluntary_r_d',
    type: 'left',
    title: 'Voluntary R&D',
    body: (
      <div className="services--info">
        <p>ContiCorp Labs offers single-assay research and development testing for potency, terpenes, microbial, pesticides, heavy metals, and solvents. This is useful when developing a new product or having contamination concerns. R&D samples are included in our quick turnaround time with expedite options available.</p>
      </div>
    ),
    img: {
      src: 'assets/images/voluntary_r_d.jpg',
      alt: 'Cannabis Image',
    }
  },
]

const ServicesPage = () => (
  <div className="page-container">
    <Header />
    <div className="page services">
    <h1 className="page__title">
        Services Offered
      </h1>
      <Spacer />
      { items.map((item, index) => {
        if (item.type === 'right') {
          return (
            <React.Fragment key={Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)}>
              <RightArticle data={item} key={`${index}--${item.title.replace(/\s+/g, '-').toLowerCase()}`} />
              { index !== (items.length - 1) && 
                <Spacer key={`s${index}-${item.title}`}/>
              }
            </React.Fragment>
          );
        } else if (item.type === 'left') {
          return (
            <React.Fragment key={Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)}>
              <LeftArticle data={item} key={`${index}--${item.title.replace(/\s+/g, '-').toLowerCase()}`} />
              { index !== (items.length - 1) && 
                <Spacer key={`s${index}-${item.title}`}/>
              }
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)}>
              <TopArticle data={item} key={`${index}--${item.title.replace(/\s+/g, '-').toLowerCase()}`} />
              { index !== (items.length - 1) && 
                <Spacer key={`s${index}-${item.title}`}/>
              }
            </React.Fragment>
          );
        }
      })}
    </div>
    <Footer />
    {/* <Background /> */}
  </div>
);

export default ServicesPage;
