import React from 'react';
import { Container } from 'reactstrap';

const Spacer = (props) => (
  <Container>
    <div id={props.id} className="spacer" style={{ height: '1px' }} />
  </Container>
);

export default Spacer;
