import React, { useState } from 'react';
import { Alert, Container, Row, Col } from 'reactstrap';
import Background from '../components/Background';
import Header from '../components/Header';
import Footer from '../components/Footer';
import RightArticle from '../components/RightArticle';
import LeftArticle from '../components/LeftArticle';
import TopArticle from '../components/TopArticle';
import Spacer from '../components/Spacer';
import SimpleList from '../components/SimpleList';
import Columns from '../components/Columns';

const showAlert = false;
const alertMessage = 'Fully commenced and accepting samples.';
const topStyle = showAlert ? {} : { marginTop: "3rem" };

const certificates = [
  {
    src: '/assets/images/WAIH.jpg',
    alt: 'Fall 2024 Water Activity In Hemp Bud Certificate Badge'
  },
  {
    src: '/assets/images/VEAIHO.jpg',
    alt: 'Fall 2024 Vitamin E Acetate In Hemp Oil Certificate Badge'
  },
  {
    src: '/assets/images/STECIH.jpg',
    alt: 'Fall 2024 STEC In Hemp Certificate Badge'
  },
  {
    src: '/assets/images/SALIH.jpg',
    alt: 'Fall 2024 SALMONELLA In Hemp Certificate Badge'
  },
  {
    src: '/assets/images/MIH.jpg',
    alt: 'Fall 2024 Moisture In Hemp Certificate Badge'
  },
  {
    src: '/assets/images/FMIH.jpg',
    alt: 'Fall 2024 Foreign Materials In Hemp Bud Certificate Badge'
  },
  {
    src: '/assets/images/AIH.jpg',
    alt: 'Fall 2024 Aspergillus In Hemp Certificate Badge'
  },
  {
    src: '/assets/images/HMIHO.jpg',
    alt: 'Fall 2024 7 Part Heavy Metals In Hemp Oil Certificate Badge'
  },
  {
    src: '/assets/images/PSIHO.jpg',
    alt: 'Fall 2024 115 Part Pesticie Screening In Hemp Oil Certificate Badge'
  },
];

const testingItems = {
  items: [
    <a href="/services#potency">Potency</a>,
    <a href="/services#terpene_profile">Terpene Profile</a>,
    <span>Contaminants Screenings:</span>,
  ],
  subItems: [
    <a href="/services#microbiological_screening">Microbial</a>,
    <a href="/services#pesticide_contamination">Chemical and Pesticide Residues</a>,
    <a href="/services#heavy_metals">Heavy Metals</a>,
    <a href="/services#residual_solvents">Residual Solvents</a>,
    <a href="/services#water_activity">Water Activity</a>,
    <a href="/services#water_activity">Moisture Content</a>,
    <a href="/services#foreign_matter">Foreign Matter</a>,
    <a href="/services#voluntary_r_d">Voluntary R&D Testing</a>,
  ]
};

const items = [
  {
    type: 'left',
    title: 'Missouri Compliance Testing',
    body: (
      <div className="home--info">
        ContiCorp Labs is a family-owned, state-licensed analytical testing laboratory in the heart of Southwest Missouri. Our expertise is taking a values-centered approach to our clients and employees, ensuring that our testing standards meet or exceed all state/industry regulations as well as client expectations. We empower our employees through our focus on commitment and engagement to drive innovation and build the future of this industry from right here in the beautiful Ozark Plateau.
        ContiCorp Labs draws from decades of experience in testing analytics and scientific rigor to provide unrivaled accuracy and continual process improvement.
      </div>
    ),
    img: {
      src: 'assets/images/Missouri_Compliance.jpg',
      alt: 'Jefferson City, Missouri state building',
    },
    links: [
      {
        title: 'Test With Us',
        url: 'https://accounts.confidentcannabis.com/login',
        external: 1,
      }
    ]
  },
  {
    type: 'right',
    title: 'Tests Performed',
    body: (
      <div className="home--info">
        <SimpleList data={testingItems} />
      </div>
    ),
    img: {
      src: 'assets/images/pipette_drop.jpg',
      alt: 'Products in test tubes',
    },
    links: [
      {
        title: 'Services Offered',
        url: '/services',
      }
    ]
  },
  {
    type: 'left',
    title: 'Transportation Services',
    body: (
      <div className="home--info">
        ContiCorp Labs staff will sample product on-site and transport to our lab for the quickest turnaround of testing results.
      </div>
    ),
    img: {
      src: 'assets/images/rainbow.jpg',
      alt: 'Row of jars image',
    },
    links: [
      {
        title: 'Contact Us',
        url: '/contact-us',
      }
    ]
  },
  {
    type: 'left',
    title: 'Certifications',
    body: (
      <div className="home--info">
        <div className="certs">
          {certificates.map((item, index) => {
            return (
              <div className="certs--item" key={`certificate-number-${index}`}>
                <img  src={item.src} alt={item.alt} />
              </div>
            )
          })}
        </div>
      </div>
    ),
    img: {
      src: 'assets/images/PJLA_accreditation.png',
      alt: 'PJLA accreditation #113702',
    }
  },
  {
    type: 'left',
    title: 'Mission Statement',
    body: (
      <div className="home--info">
        Conti Corp Labs will act in the Protection of public health to provide accurate, validated, and regulatory-ready reports and data for manufacturers and retailers seeking access to commercial markets.
      </div>
    ),
    img: {
      src: 'assets/images/1429930523.jpg',
      alt: 'Medical Marijuana Testing Image',
    }
  },
]

const HomePage = () => {
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);
  return (
    <div className="page-container">
      <Header />
      <div className="page home" style={topStyle}>
        { showAlert && 
          <div className="alert-wrapper">
            <Alert color="primary" isOpen={visible} toggle={onDismiss}>
              {alertMessage}
            </Alert>
          </div>
        }
        { items.map((item, index) => {
          if (item.type === 'right') {
            return (
              <React.Fragment key={Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)}>
                <RightArticle data={item} key={`${index}--${item.title.replace(/\s+/g, '-').toLowerCase()}`} />
                { index !== (items.length - 1) && 
                  <Spacer key={`s${index}-${item.title}`}/>
                }
              </React.Fragment>
            );
          } else if (item.type === 'left') {
            return (
              <React.Fragment key={Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)}>
                <LeftArticle data={item} key={`${index}--${item.title.replace(/\s+/g, '-').toLowerCase()}`} />
                { index !== (items.length - 1) && 
                  <Spacer key={`s${index}-${item.title}`}/>
                }
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)}>
                <TopArticle data={item} key={`${index}--${item.title.replace(/\s+/g, '-').toLowerCase()}`} />
                { index !== (items.length - 1) && 
                  <Spacer key={`s${index}-${item.title}`}/>
                }
              </React.Fragment>
            );
          }
        })}
        <div className='container'>
          <Container className="column__wrapper">
            <Row className="row__wrapper">
              <Col className="col--center">
                <div className='affiliate'>
                  <h2 className='affiliate__title'>
                    Instrumentation Partner
                  </h2>
                  <a href="https://www.shimadzu.com">
                    <img src="assets/images/shimadzu-logo.png" alt="Shimadzu Logo" />
                  </a>
                </div>
              </Col>
              <Col className="col--center">
                <div className='affiliate'>
                  <h2 className='affiliate__title'>
                    Partners
                  </h2>
                  <a href="https://www.visittablerocklake.com/chamber-home/">
                    <img style={{maxHeight: "150px"}} src="assets/images/TRL-Chamber-Member-Logo-web1.png" alt="Table Rock Lake Chamber of Commerce Logo" />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
      {/* <Background /> */}
    </div>
  );
}

export default HomePage;
