import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Spacer from '../components/Spacer';
import AccordionItem from '../components/AccordionItem';
import MyLink from '../components/myLink';

const accordionItems = [
  {
    title: 'What is your typical turnaround time?',
    body: (
      <p>
        Our turn-around time for results is 3-5 business days, with the option to expedite results in 2-3 business days for an additional expedite fee. 
      </p>
    ),
  },
  {
    title: 'What are your prices?',
    body: (
      <p>
        Our prices are very competitive in the industry. Please call or email for pricing.
      </p>
    ),
  },
  {
    title: 'Will you pick up samples from outlying areas?',
    body: (
      <p>
        Yes, we serve all of Missouri with next-day pickup availability.
      </p>
    ),
  },
  {
    title: 'How do I receive my test results?',
    body: (
      <p>
        Our laboratory uses Confident Cannabis with Metrc integration to report test results to our clients.
      </p>
    ),
  },
  {
    title: 'Do you give discounts for larger volume testing?',
    body: (
      <p>
        Yes, volume discounts are available. Please email or call for discounted rates.
      </p>
    ),
  },
  {
    title: 'Can I submit a product for testing if I don’t have a license?',
    body: (
      <p>
        Yes, we can do R&D testing if you are a valid medical patient.
      </p>
    ),
  },
  {
    title: 'Can I drop off my sample?',
    body: (
      <p>
        Valid medical license holders may drop off samples at the lab. Our licensed transport drivers will pick up samples on-site for state-licensed manufacturing or cultivation facilities.
      </p>
    ),
  },
  {
    title: 'How can I pay?',
    body: (
      <p>
        ACH is preferred; inquire for other payment methods.
      </p>
    ),
  },
  {
    title: 'What are your hours of operation?',
    body: (
      <p>
        Our normal hours are Monday through Friday, 8:30 to 5:00 p.m. We are closed on Saturdays and Sundays.
      </p>
    ),
  }
]

const FAQPage = () => (
    <div className="page-container">
      <Header />
      <div className="page faqs">
        <h1 className="page__title">
          FAQs
        </h1>
        <Spacer />
        <Container className="article__wrapper">
          {accordionItems.map((item, index) => (
            <AccordionItem data={item} key={index} />
          ))}
        </Container>
        <Spacer />
        <div className='container'>
          <Container className="column__wrapper">
            <Row className="row__wrapper">
              <Col className="col--center">
                <div className='affiliate'>
                  <h2 className='affiliate__title'>
                    Test With Us!
                  </h2>
                  <MyLink data={{ title: 'Confident Cannabis', url: 'https://accounts.confidentcannabis.com/login'}} />
                </div>
              </Col>
              <Col className="col--center">
                <div className='affiliate'>
                  <h2 className='affiliate__title'>
                    Additional Resources
                  </h2>
                  <MyLink data={{ title: 'Know Your Cannabis Campaign', url: 'https://knowyourcannabis.org'}} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
      {/* <Background /> */}
    </div>
);

export default FAQPage;
